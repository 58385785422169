import { useNavigate, useSearchParams } from "react-router-dom";
import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import {
  getDriverAsync,
  selectGetIsDriverKnownStatus,
  selectIsDriverKnown,
} from "./driverKnownSlice";
import { selectQrCodeId } from "./vehicleLoginSlice";
import { ErrorPage } from "../components/ErrorPage";
import { LaunchPage } from "@qivia/ui";
import {
  driverLoginSlice,
  selectIsPhoneTokenDecoded,
} from "./driverLoginSlice";

export const DriverKnown = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const isDriverKnown = useAppSelector(selectIsDriverKnown);
  const isDriverKnownStatus = useAppSelector(selectGetIsDriverKnownStatus);
  const qrCodeId = useAppSelector(selectQrCodeId);
  const [urlSearchParams] = useSearchParams();
  const isPhoneTokenDecoded = useAppSelector(selectIsPhoneTokenDecoded);

  useEffect(() => {
    if (!qrCodeId) return;
    void dispatch(getDriverAsync({ uuid: qrCodeId }));
  }, [dispatch, navigate, qrCodeId]);

  useEffect(() => {
    if (isDriverKnownStatus !== "success") return;

    if (isDriverKnown) {
      navigate("/menu");
    } else if (urlSearchParams.get("preserve_token") !== "true") {
      localStorage.removeItem("phoneToken");
      dispatch(driverLoginSlice.actions.decodePhoneToken());
    } else {
      navigate("/driver_register");
    }
  }, [isDriverKnownStatus, isDriverKnown, navigate, dispatch, urlSearchParams]);

  useEffect(() => {
    const localStorageToken = localStorage.getItem("phoneToken");
    if (isDriverKnownStatus !== "success") return;
    if (isDriverKnown) return;
    if (urlSearchParams.get("preserve_token") === "true") return;
    if (!isPhoneTokenDecoded) return;
    if (localStorageToken) return;
    navigate("/driver_login");
  }, [
    isPhoneTokenDecoded,
    isDriverKnown,
    isDriverKnownStatus,
    navigate,
    dispatch,
    urlSearchParams,
  ]);

  if (isDriverKnownStatus === "processing") {
    return <LaunchPage />;
  }

  if (isDriverKnownStatus === "failed" || qrCodeId === null)
    return <ErrorPage page="qrCode" />;
};
