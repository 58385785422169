// Externally-visible signature
import axiosLib from "axios";

export const axiosInstance = () =>
  axiosLib.create({
    baseURL: `${import.meta.env["VITE_BACKEND_URL"] as string}/office`,
    timeout: 60000,
    headers: { Authorization: `Bearer` },
  });

export const axiosInstancePhoneValidator = () =>
  axiosLib.create({
    baseURL: `${
      import.meta.env["VITE_BACKEND_PHONE_VALIDATOR_URL"] as string
    }/phone-validator`,
    timeout: 60000,
    headers: { Authorization: `Bearer` },
  });

export type QueryStatus = "idle" | "processing" | "success" | "failed";
export const axios = axiosInstance();

export const axiosInstanceWithPhoneToken = (token: string) =>
  axiosLib.create({
    baseURL: `${import.meta.env["VITE_BACKEND_URL"] as string}/office`,
    timeout: 60000,
    headers: { Authorization: `Bearer ${token}` },
  });

export const isDateOver24h = (date: string) => {
  const today = new Date(Date.now());
  const oneDayPast = new Date(today.setDate(today.getDate() - 1));
  return oneDayPast > new Date(date);
};
