import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../redux/store";
import { QueryStatus, axiosInstance } from "../../utils";
import {
  PublicTransactionDataType,
  TransactionDataApi,
  UpdateTransactionDataType,
} from "./transactionDataFormAPI";
import { CategoryType } from "./transactionsListAPI";

export interface transactionDataFormState {
  historyTransactionStatus: QueryStatus;
  updateTransactionDataStatus: QueryStatus;
  historyTransaction: PublicTransactionDataType;
}

const initialState: transactionDataFormState = {
  historyTransactionStatus: "idle",
  updateTransactionDataStatus: "idle",
  historyTransaction: {
    fileName: "",
    fileId: "",
    uploadStatus: "",
    mileage: null,
    quantity: null,
    isFuelExpense: false,
    date: "",
    areSupportingDocumentsMandatory: true,
    isMileageMandatory: true,
  },
};

export const transactionHistoryDataAsync = createAsyncThunk(
  "transactionDataForm/call",
  async (transactionId: string) => {
    const axios = axiosInstance();
    const response = await axios.get<TransactionDataApi>(
      `driver_access/transaction/${transactionId}/data_history`,
    );
    return {
      ...response.data,
      fileName: response.data.originalFileName,
      uploadStatus: response.data.status,
      fileId: response.data.supportingDocumentUuid,
      isFuelExpense: (
        ["FUEL_AUTOMATON", "SERVICE_STATION", "ELECTRIC"] as CategoryType[]
      ).includes(response.data.category),
    };
  },
);

export const updateTransactionDataAsync = createAsyncThunk(
  "updateTransactionData/call",
  async (payload: UpdateTransactionDataType) => {
    const axios = axiosInstance();
    await axios.post(
      `driver_access/transaction/${payload.transactionId}/update`,
      { mileage: payload.mileage, quantity: payload.quantity },
    );
    return payload;
  },
);

export const transactionDataFormSlice = createSlice({
  name: "upload_supporting_document",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    mileageBlur(state, action: PayloadAction<number | null>) {
      state.historyTransaction.mileage = action.payload;
    },
    quantityBlur(state, action: PayloadAction<number | null>) {
      state.historyTransaction.quantity = action.payload;
    },
    resetUpdateStatus(state) {
      state.updateTransactionDataStatus = "idle";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(transactionHistoryDataAsync.pending, (state) => {
        state.historyTransactionStatus = "processing";
      })
      .addCase(transactionHistoryDataAsync.fulfilled, (state, action) => {
        state.historyTransaction = action.payload;
        state.historyTransactionStatus = "success";
      })
      .addCase(transactionHistoryDataAsync.rejected, (state) => {
        state.historyTransactionStatus = "failed";
      })
      .addCase(updateTransactionDataAsync.pending, (state) => {
        state.updateTransactionDataStatus = "processing";
      })
      .addCase(updateTransactionDataAsync.fulfilled, (state, action) => {
        state.historyTransaction.mileage = action.payload.mileage;
        state.historyTransaction.quantity = action.payload.quantity;
        state.updateTransactionDataStatus = "success";
      })
      .addCase(updateTransactionDataAsync.rejected, (state) => {
        state.updateTransactionDataStatus = "failed";
      });
  },
});

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const selectHistoryTransactionDataStatus = (state: RootState) =>
  state.transactionDataForm.historyTransactionStatus;

export const selectUpdateTransactionDataStatus = (state: RootState) =>
  state.transactionDataForm.updateTransactionDataStatus;

export const selectTransactionData = (state: RootState) =>
  state.transactionDataForm.historyTransaction;
export default transactionDataFormSlice.reducer;
