import { DriverLoadingPage } from "@qivia/ui";
import { useNavigate, useSearchParams } from "react-router-dom";
import { ErrorPage } from "../components/ErrorPage";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { useCallback, useEffect } from "react";
import {
  selectHistoryTransactionDataStatus,
  selectTransactionData,
  transactionHistoryDataAsync,
} from "../transactions/transactionDataFormSlice";
import { QueryStatus, isDateOver24h } from "../../utils";
import { PublicTransactionDataType } from "../transactions/transactionDataFormAPI";

export const SupportingDocumentMenu = () => {
  const dispatch = useAppDispatch();

  const [queryParameters] = useSearchParams();
  const transactionId = queryParameters.get("id");
  const navigate = useNavigate();
  const transactionHistoryStatus: QueryStatus = useAppSelector(
    selectHistoryTransactionDataStatus,
  );
  const transactionData = useAppSelector(selectTransactionData);

  const hasMissingMileage = useCallback(
    (transaction: PublicTransactionDataType): boolean => {
      return (
        transaction.isFuelExpense &&
        transaction.isMileageMandatory &&
        !transaction.mileage &&
        !isDateOver24h(transaction.date)
      );
    },
    [],
  );

  const hasMissingSupportingDocument = useCallback(
    (transaction: PublicTransactionDataType): boolean => {
      return (
        transaction.fileId === "" && transaction.areSupportingDocumentsMandatory
      );
    },
    [],
  );

  useEffect(() => {
    if (transactionId) {
      void dispatch(transactionHistoryDataAsync(transactionId));
    }
  }, [dispatch, transactionId]);

  useEffect(() => {
    if (transactionId && transactionHistoryStatus === "success") {
      if (hasMissingSupportingDocument(transactionData)) {
        navigate(`/upload_document?id=${transactionId}`);
      } else if (hasMissingMileage(transactionData)) {
        navigate(`/fuel_data_form?id=${transactionId}`);
      } else {
        navigate(`/transaction?id=${transactionId}`);
      }
    }
  }, [
    dispatch,
    hasMissingMileage,
    hasMissingSupportingDocument,
    navigate,
    transactionData,
    transactionData.date,
    transactionData.fileId,
    transactionData.isFuelExpense,
    transactionData.mileage,
    transactionHistoryStatus,
    transactionId,
  ]);

  if (transactionHistoryStatus === "processing") {
    return <DriverLoadingPage />;
  }

  if (transactionId === null) {
    return <ErrorPage />;
  }
};
