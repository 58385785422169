import styled from "styled-components";
import {
  Button,
  DriverLoadingPage,
  DriverSuccessPage,
  ToastContainer,
} from "@qivia/ui";
import { useTranslation } from "react-i18next";
import { useNavigate, useSearchParams } from "react-router-dom";
import { TransactionPage } from "../transactions/transactionPage";
import { ErrorPage } from "../components/ErrorPage";
import { UploadSupportingDocumentButton } from "./uploadSupportingDocumentButton";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { selectUploadSupportingDocumentToS3Status } from "./uploadSupportingDocumentSlice";
import { useEffect, useState } from "react";
import { selectTransactionData } from "../transactions/transactionDataFormSlice";
import { getCurrentTransactionFormatted } from "../transactions/transactionPageSlice";

export const UploadSupportingDocument = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const [queryParameters] = useSearchParams();
  const [isExpenseCompleted, setIsExpenseCompleted] = useState<boolean>(false);
  const transactionId = queryParameters.get("id");
  const navigate = useNavigate();

  const uploadSupportingDocumentToS3Status = useAppSelector(
    selectUploadSupportingDocumentToS3Status,
  );
  const transactionData = useAppSelector(selectTransactionData);
  const currentTransaction = useAppSelector(getCurrentTransactionFormatted);

  useEffect(() => {
    if (
      ["success", "error"].includes(uploadSupportingDocumentToS3Status) &&
      transactionId
    )
      if (
        transactionData.isFuelExpense &&
        !transactionData.mileage &&
        transactionData.isMileageMandatory
      ) {
        navigate(`/fuel_data_form?id=${transactionId}`);
      } else {
        setIsExpenseCompleted(true);
      }
  }, [
    dispatch,
    navigate,
    transactionData.isFuelExpense,
    transactionData.isMileageMandatory,
    transactionData.mileage,
    transactionId,
    uploadSupportingDocumentToS3Status,
  ]);

  if (transactionId === null) {
    return <ErrorPage />;
  }

  if (isExpenseCompleted) {
    return (
      <DriverSuccessPage
        title={t("success.expense.title")}
        subTitle={t("success.expense.subTitle")}
      />
    );
  }
  if (uploadSupportingDocumentToS3Status === "processing") {
    return <DriverLoadingPage />;
  }
  return (
    <TransactionPage toaster={<ToastContainer />}>
      <StyledContainer>
        <UploadSupportingDocumentButton transactionId={transactionId}>
          <Button title={t("supportingDocument.add") || ""} height={3.5} />
        </UploadSupportingDocumentButton>
        {currentTransaction?.status !== "PENDING_ADJUSTEMENT" && (
          <Button
            title={t("supportingDocument.empty") || ""}
            onClick={() =>
              navigate(`/no_supporting_document?id=${transactionId}`)
            }
            withoutBackground
            height={3.5}
            underline
          />
        )}
      </StyledContainer>
    </TransactionPage>
  );
};

const StyledContainer = styled.div`
  width: 100%;
`;
