import { HTMLAttributes } from "react";
import { RowElement } from "./RowElement";
import { KeyboardArrowRightRound } from "../../materialUi/materialUi";

export interface RowClickableProps extends HTMLAttributes<HTMLButtonElement> {
  text: string;
  subText?: string;
  icon?: JSX.Element;
  onClick: () => void;
}

export const RowClickable = (props: RowClickableProps) => {
  return (
    <RowElement
      rightElement={<KeyboardArrowRightRound />}
      leftIcon={props.icon}
      size={"L"}
      onClickRow={props.onClick}
      text={props.text}
      subText={props.subText}
    />
  );
};
