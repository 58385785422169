import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  driverCanPayAsync,
  getCardStatusAsync,
  lastTermsAndPolicyApprovedAsync,
  selectAreLastTermsAndPolicyApproved,
  selectCardStatus,
  selectCardUpdatedStatus,
  selectDriverCanPayStatus,
  selectDriverPaymentRefusedReasons,
  selectLastTermsAndPolicyStatus,
  updateCardStatusAsync,
  bindVehicleAsync,
  selectBindVehicleMessage,
  selectBindVehicleStatus,
  selectActiveDriverByPhoneStatus,
  selectActiveDriverByPhone,
  getActiveDriverByPhoneAsync,
  getCardExpensesAsync,
  selectCardExpenses,
  selectCardExpensesStatus,
} from "./menuSlice";
import { ReasonsRefusedEnum } from "./menuApi";
import {
  Button,
  Checkbox,
  DriverLoadingPage,
  Spacer,
  TextCapitalized,
} from "@qivia/ui";

import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import {
  driverTermsAndPolicyApprovedAsync,
  selectDriverPhone,
  selectDriverTermsAndPolicyApprovedStatus,
  selectIsPhoneTokenDecoded,
} from "./driverLoginSlice";
import {
  selectCardHolderIdentification,
  selectCompanyUuid,
} from "./vehicleLoginSlice";
import { useLocation, useNavigate } from "react-router-dom";
import { ErrorPage } from "../components/ErrorPage";
import { DriverPaymentPage } from "../components/DriverPaymentPage";
import { colors } from "@qivia/ui/src/styles/figmaColors";
import styled from "styled-components";
import { Page } from "../components/Page";
import TermsAndPolicySentence from "./termsAndPolicySentence";

export const Menu = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const cardHolderIdentification = useAppSelector(
    selectCardHolderIdentification,
  );
  const driverPhone = useAppSelector(selectDriverPhone);
  const isPhoneTokenDecoded = useAppSelector(selectIsPhoneTokenDecoded);
  const bindVehicleStatus = useAppSelector(selectBindVehicleStatus);
  const bindVehicleMessage = useAppSelector(selectBindVehicleMessage);
  const lastTermsAndPolicyStatus = useAppSelector(
    selectLastTermsAndPolicyStatus,
  );
  const areLastTermsAndPolicyAccepted = useAppSelector(
    selectAreLastTermsAndPolicyApproved,
  );
  const approvalTermsAndPolicyStatus = useAppSelector(
    selectDriverTermsAndPolicyApprovedStatus,
  );
  const [needApprovalLastTermsAndPolicy, setNeedApprovalLastTermsAndPolicy] =
    useState<boolean | null>(null);
  const [isCardLocked, setIsCardLocked] = useState<boolean | null>(null);

  const driverPaymentRefusedReasons = useAppSelector(
    selectDriverPaymentRefusedReasons,
  );
  const [reasonIndex, setReasonIndex] = useState<number>(0);
  const status = useAppSelector(selectCardStatus);
  const updateCardStatus = useAppSelector(selectCardUpdatedStatus);
  const isInOpposition = status === "IN_OPPOSITION";
  const driverCanPayStatus = useAppSelector(selectDriverCanPayStatus);
  const getActiveDriverByPhoneStatus = useAppSelector(
    selectActiveDriverByPhoneStatus,
  );
  const activeDriverByPhone = useAppSelector(selectActiveDriverByPhone);
  const getCardExpensesStatus = useAppSelector(selectCardExpensesStatus);
  const cardExpenses = useAppSelector(selectCardExpenses);
  const companyUuid = useAppSelector(selectCompanyUuid);

  const reasonsRefusedDict = useMemo(
    () => [
      "IN_OPPOSITION_CARD",
      "ROTATION_REQUEST_EXCEEDED",
      "ROTATION_REQUEST_TRANSACTIONS_EXCEEDED",
      "ALREADY_REJECTED_ROTATION_REQUEST",
      "INACTIVE_CARD",
      "EXCEEDED_LIMITS",
      "EXCEEDED_TIME",
      "WRONG_DAY",
      "WRONG_TIME",
      "HOLIDAYS_NOT_ALLOWED",
    ],
    [],
  );

  const reasonsRefusedSorted = reasonsRefusedDict.filter((reason) => {
    return driverPaymentRefusedReasons.includes(reason as ReasonsRefusedEnum);
  });

  const paymentTitle = useMemo(() => {
    return driverPaymentRefusedReasons.length > 0
      ? t(
          `driverPayment.${reasonsRefusedSorted[
            reasonIndex
          ]?.toLowerCase()}.title`,
        )
      : t("driverPayment.success.title");
  }, [driverPaymentRefusedReasons, reasonsRefusedSorted, reasonIndex, t]);

  const paymentSubTitle = useMemo(() => {
    return driverPaymentRefusedReasons.length > 0
      ? t(
          `driverPayment.${reasonsRefusedSorted[
            reasonIndex
          ]?.toLowerCase()}.subTitle`,
        )
      : t("driverPayment.success.subTitle");
  }, [driverPaymentRefusedReasons, reasonsRefusedSorted, reasonIndex, t]);

  useEffect(() => {
    if (
      lastTermsAndPolicyStatus === "success" &&
      !areLastTermsAndPolicyAccepted
    ) {
      setNeedApprovalLastTermsAndPolicy(true);
    } else if (
      lastTermsAndPolicyStatus === "success" &&
      areLastTermsAndPolicyAccepted
    ) {
      setNeedApprovalLastTermsAndPolicy(false);
    }
  }, [areLastTermsAndPolicyAccepted, lastTermsAndPolicyStatus, navigate]);

  useEffect(() => {
    if (
      isPhoneTokenDecoded &&
      driverPhone &&
      cardHolderIdentification &&
      companyUuid
    ) {
      void dispatch(
        bindVehicleAsync({
          cardHolderIdentification,
        }),
      );
      void dispatch(getActiveDriverByPhoneAsync({ companyUuid }));
      void dispatch(getCardExpensesAsync({ cardHolderIdentification }));
    }
  }, [
    cardHolderIdentification,
    companyUuid,
    dispatch,
    driverPhone,
    isPhoneTokenDecoded,
    location,
  ]);

  useEffect(() => {
    if (bindVehicleStatus !== "success" || !cardHolderIdentification) return;
    if (bindVehicleMessage === "driver not found")
      navigate("/driver_not_found");
    if (bindVehicleMessage === "driver blocked") navigate("/driver_blocked");
    void dispatch(
      lastTermsAndPolicyApprovedAsync({
        cardHolderIdentification,
      }),
    );
  }, [
    bindVehicleMessage,
    bindVehicleStatus,
    dispatch,
    navigate,
    cardHolderIdentification,
    t,
  ]);

  useEffect(() => {
    if (updateCardStatus === "success" && cardHolderIdentification) {
      void dispatch(getCardStatusAsync({ cardHolderIdentification }));
      void dispatch(driverCanPayAsync({ cardHolderIdentification }));
    }
  }, [dispatch, cardHolderIdentification, updateCardStatus]);

  useEffect(() => {
    if (
      approvalTermsAndPolicyStatus === "success" &&
      cardHolderIdentification
    ) {
      setNeedApprovalLastTermsAndPolicy(false);
    }
  }, [approvalTermsAndPolicyStatus, dispatch, cardHolderIdentification]);

  useEffect(() => {
    if (reasonsRefusedSorted.length > 0) {
      setReasonIndex(0);
    }
  }, [reasonsRefusedDict, reasonsRefusedSorted]);

  useEffect(() => {
    if (isCardLocked === null && status) {
      setIsCardLocked(status === "LOCKED");
    }
  }, [isCardLocked, status]);

  if (cardHolderIdentification && !status) {
    void dispatch(getCardStatusAsync({ cardHolderIdentification }));
  }

  if (
    cardHolderIdentification &&
    driverCanPayStatus === "idle" &&
    bindVehicleStatus === "success"
  ) {
    void dispatch(driverCanPayAsync({ cardHolderIdentification }));
  }

  if (
    driverCanPayStatus === "processing" ||
    lastTermsAndPolicyStatus === "processing" ||
    bindVehicleStatus === "processing" ||
    getActiveDriverByPhoneStatus === "processing" ||
    getCardExpensesStatus === "processing"
  ) {
    return <DriverLoadingPage />;
  }

  if (!isPhoneTokenDecoded || !driverPhone || !cardHolderIdentification)
    return <ErrorPage page="qrCode" />;

  const lockCard = (isCardLocked: boolean) => {
    setIsCardLocked(isCardLocked);
    void dispatch(
      updateCardStatusAsync({
        cardHolderIdentification,
        status: isCardLocked ? "LOCKED" : "UNLOCKED",
      }),
    );
  };

  if (needApprovalLastTermsAndPolicy) {
    return (
      <TermsAndPolicy
        cardHolderIdentification={cardHolderIdentification}
        sentence={<TermsAndPolicySentence />}
      />
    );
  }

  return (
    <DriverPaymentPage
      title={paymentTitle}
      subTitle={paymentSubTitle}
      isInOpposition={isInOpposition}
      isCardLocked={isCardLocked ?? false}
      state={
        driverPaymentRefusedReasons.length > 0 ? "UNAUTHORIZED" : "AUTHORIZED"
      }
      changeCardStatus={() => lockCard(!isCardLocked)}
      disabled={updateCardStatus === "processing"}
      isRotationRequestExceeded={driverPaymentRefusedReasons.includes(
        "ROTATION_REQUEST_EXCEEDED",
      )}
      activeDriverByPhone={activeDriverByPhone}
      cardExpenses={cardExpenses}
    />
  );
};

const TermsAndPolicy = (props: {
  cardHolderIdentification: string;
  sentence: JSX.Element;
}) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [isChecked, setIsChecked] = useState<boolean>(false);
  const [displayError, setDisplayError] = useState<boolean>(false);

  return (
    <Page
      title={t(`driverLogin.termsAndPolicy.title`)}
      bottomButton={
        <Button
          title={t("driverLogin.button.next")}
          onClick={() => {
            if (isChecked) {
              void dispatch(
                driverTermsAndPolicyApprovedAsync({
                  cardHolderIdentification: props.cardHolderIdentification,
                }),
              );
              setDisplayError(false);
            } else {
              setDisplayError(true);
            }
          }}
          height={3.5}
          fontSize={1.1}
          disabled={!isChecked}
        />
      }
      helpButton
    >
      <StyledContainer>
        <Spacer y={1} />
        <StyledBody>
          <Spacer x={0.25} />
          <TextCapitalized>
            {t(`driverLogin.termsAndPolicy.subTitle`)}
          </TextCapitalized>
          <Spacer x={0.25} />
        </StyledBody>
        <Spacer y={2} />
        <StyledBottom>
          <Spacer x={0.5} />
          <Checkbox
            borderColor={
              isChecked ? "transparent" : colors["colors/text/darkGrey"]
            }
            size={1}
            text={props.sentence}
            check={isChecked}
            onClick={() => setIsChecked(!isChecked)}
          />
          <Spacer x={0.5} />
        </StyledBottom>
        {displayError && !isChecked && (
          <StyledErrorMessage>
            <Spacer y={1} />
            <TextCapitalized>
              {t("driverLogin.termsAndPolicy.error")}
            </TextCapitalized>
          </StyledErrorMessage>
        )}
        <Spacer y={2} />
      </StyledContainer>
    </Page>
  );
};

const StyledBottom = styled.div`
  display: flex;
  align-items: flex-end;
  height: 100%;
`;

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
`;

const StyledBody = styled.div`
  display: flex;
  width: 100%;
  color: ${colors["colors/text/black"]};
`;

const StyledErrorMessage = styled.div`
  display: flex;
  flex-direction: column;
  color: ${colors["colors/system/error/error_normal"]};
`;
