import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { TransactionPage } from "./transactionPage";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import {
  transactionDataFormSlice,
  selectUpdateTransactionDataStatus,
  updateTransactionDataAsync,
  selectTransactionData,
  selectHistoryTransactionDataStatus,
  transactionHistoryDataAsync,
} from "./transactionDataFormSlice";
import { useCallback, useEffect, useState } from "react";
import { Spacer, ToastContainer, triggerToast, TextClickable } from "@qivia/ui";
import { ErrorPage } from "../components/ErrorPage";
import { QueryStatus } from "../../utils";
import { selectUpdateSupportingDocumentState } from "../supportingDocuments/uploadSupportingDocumentSlice";
import { transactionDataAsync } from "./transactionPageSlice";
import { SupportingDocument } from "../components/SupportingDocument";
import { MileageInput } from "../components/MileageInput";

export const TransactionDataForm = () => {
  const { t } = useTranslation();
  const [queryParameters] = useSearchParams();
  const transactionId = queryParameters.get("id");

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const transactionHistoryStatus: QueryStatus = useAppSelector(
    selectHistoryTransactionDataStatus,
  );
  const updateTransactionDataStatus = useAppSelector(
    selectUpdateTransactionDataStatus,
  );
  const transactionData = useAppSelector(selectTransactionData);
  const [mileage, setMileage] = useState<number | "">("");
  const updateSupportingDocumentStatusState = useAppSelector(
    selectUpdateSupportingDocumentState,
  );

  useEffect(() => {
    if (transactionId && transactionHistoryStatus === "success") {
      setMileage(transactionData.mileage ?? "");
    }
  }, [
    dispatch,
    transactionData.mileage,
    transactionData.quantity,
    transactionHistoryStatus,
    transactionId,
  ]);

  useEffect(() => {
    if (updateTransactionDataStatus === "success") {
      triggerToast(
        t("transactionDataForm.transactionData.register.success") ?? "",
        "valid",
      );
    } else if (updateTransactionDataStatus === "failed") {
      triggerToast(
        t("transactionDataForm.transactionData.register.error") ?? "",
        "error",
      );
    }
    dispatch(transactionDataFormSlice.actions.resetUpdateStatus());
  }, [dispatch, t, updateTransactionDataStatus]);

  useEffect(() => {
    if (transactionId) {
      void dispatch(transactionHistoryDataAsync(transactionId));
    }
  }, [dispatch, transactionId]);

  useEffect(() => {
    if (transactionId) {
      void dispatch(transactionDataAsync(transactionId));
    }
  }, [dispatch, transactionId, updateSupportingDocumentStatusState]);

  const clickToSubmitMileage = useCallback(() => {
    if (!transactionId) return;
    void dispatch(
      updateTransactionDataAsync({
        transactionId,
        mileage: mileage !== "" ? mileage : null,
        quantity: transactionData.quantity,
      }),
    );
  }, [dispatch, mileage, transactionData.quantity, transactionId]);

  if (!transactionId) {
    return <ErrorPage />;
  }

  return (
    <TransactionPage
      toaster={<ToastContainer />}
      returnButtonText={t("transactionDataForm.returnButton.body").toString()}
    >
      <StyledContainer>
        <StyledHelp>
          <TextClickable capitalized onClick={() => navigate("/help")}>
            {t("transactionDataForm.needHelp")}
          </TextClickable>
        </StyledHelp>
        <Spacer y={1.5} />
        {transactionData.isFuelExpense &&
          transactionData.isMileageMandatory && (
            <MileageInput
              mileage={mileage}
              setMileage={setMileage}
              onKeyDownAction={clickToSubmitMileage}
              updateTransactionDataAsync={clickToSubmitMileage}
            />
          )}
        {transactionData.areSupportingDocumentsMandatory && (
          <SupportingDocument
            transactionId={transactionId}
            supportingDocumentName={transactionData?.fileName || null}
            supportingDocumentId={transactionData?.fileId || null}
          />
        )}
      </StyledContainer>
    </TransactionPage>
  );
};

const StyledContainer = styled.div`
  width: 100%;
`;

const StyledHelp = styled.div`
  display: flex;
  justify-content: center;
  font-weight: 500;
`;
